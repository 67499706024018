import React, { useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  FormGroup,
  Form,
} from "reactstrap";
import Loader from "./Loader";

const ConfirmationModalWithInput = ({
  onSubmit,
  onClose,
  title,
  body,
  confirmColor,
  confirmText,
  confirmTextPlaceholder,
  confirmTextType = "text",
}) => {
  const [loading, setLoading] = useState(false);
  const [confirmInput, setConfirmInput] = useState();

  const doSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    onSubmit(confirmInput);
  };

  return (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader toggle={onClose} className="d-flex justify-content-between">
        {title}
      </ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody className="text-center">
          {loading ? (
            <Loader size="sm" />
          ) : (
            <div>
              <p>{body}</p>
              <FormGroup className="col-12">
                <input
                  className="form-control-redesign"
                  type={confirmTextType}
                  placeholder={confirmTextPlaceholder}
                  required={true}
                  maxLength="50"
                  value={confirmInput || ""}
                  onChange={(evt) => setConfirmInput(evt.target.value)}
                />
              </FormGroup>
            </div>
          )}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={onClose} className="text-dark">
            Cancelar
          </Button>{" "}
          <Button
            disabled={
              loading ||
              (confirmText
                ? (confirmInput || "").toLowerCase() !==
                (confirmText || "").toLowerCase()
                : !confirmInput?.length)
            }
            color={confirmColor || "primary"}
            type="submit"
          >
            Confirmar
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default ConfirmationModalWithInput;
