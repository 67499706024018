import React, { useEffect, useState } from "react";

import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormGroup,
  Label,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

import {
  useCreateEmployeeRole,
  useUpdateEmployeeRole,
  useGetEmployeeRoleScopes,
  useGetEmployeeRoles,
} from "../api/EmployeeRoles.hooks";

import { components, sharedHelper, data } from "@crewos/shared";
import { EditPerDiemRulesModal } from "@crewos/perdiems";

const { useAuth } = data;

const { Loader, CustomCheckbox, ConfirmationModal } = components;

const IS_ADMIN_USER = "IS_ADMIN_USER";

const PER_DIEM_PACKAGE = "perdiems";

const EmployeeRoleModal = ({ employeeRoleId, onClose, onSubmit }) => {
  const [authContext] = useAuth();

  const [employeeRoleData, setEmployeeRoleData] = useState({
    employeeRolePerDiemRules: [],
    isActive: true,
  });

  const [employeeRolePermissions, setEmployeeRolePermissions] = useState([]);

  const [editPerDiemRulesModal, setEditPerDiemRulesModal] = useState();
  const [availableRoleScopes, setAvailableRoleScopes] = useState([]);

  const [confirmationModal, setConfirmationModal] = useState();

  const {
    data: employeeRole,
    isLoading: isLoadingEmployeeRoles,
    get: getEmployeeRoles,
  } = useGetEmployeeRoles({
    id: employeeRoleId,
  });

  useEffect(() => {
    if (employeeRoleId) {
      getEmployeeRoles({ id: employeeRoleId });
    }
  }, [getEmployeeRoles, employeeRoleId]);

  useEffect(() => {
    if (employeeRole) {
      setEmployeeRoleData(employeeRole);
      setEmployeeRolePermissions(employeeRole.employeeRoleScopes);
    }
  }, [employeeRole, setEmployeeRoleData]);

  const {
    isLoading: isLoadingUpdateEmployeeRole,
    update: updateEmployeeRole,
    data: updateEmployeeRoleData,
  } = useUpdateEmployeeRole();

  const {
    isLoading: isLoadingCreateEmployeeRole,
    mutate: createEmployeeRole,
    data: createEmployeeRoleData,
  } = useCreateEmployeeRole();

  const {
    data: getEmployeeRoleScopesData,
    isLoading: isLoadingGetEmployeeRoleScopes,
    get: getEmployeeRoleScopes,
  } = useGetEmployeeRoleScopes();

  useEffect(() => {
    getEmployeeRoleScopes();
  }, [getEmployeeRoleScopes]);

  useEffect(() => {
    if (getEmployeeRoleScopesData) {
      setAvailableRoleScopes(getEmployeeRoleScopesData);
    }
  }, [getEmployeeRoleScopesData, setAvailableRoleScopes]);

  useEffect(() => {
    if (updateEmployeeRoleData) {
      sharedHelper.successToast(`Employee role saved`);
      onSubmit();
    }
  }, [updateEmployeeRoleData, onSubmit]);

  useEffect(() => {
    if (createEmployeeRoleData) {
      sharedHelper.successToast(`Employee role created`);
      onSubmit();
    }
  }, [createEmployeeRoleData, onSubmit]);

  const doSubmit = async (e) => {
    e.preventDefault();
    const data = { ...employeeRoleData };
    const isAdmin = sharedHelper.userMeetsRole(authContext.userData, [
      IS_ADMIN_USER,
    ]);
    if (isAdmin) {
      data.employeeRoleScopes = employeeRolePermissions;
    } else {
      delete data.employeeRoleScopes;
      delete data.employeeRolePerDiemRules;
    }
    if (employeeRoleId) {
      await updateEmployeeRole({ id: employeeRoleId, ...data });
    } else {
      await createEmployeeRole(data);
    }
  };

  const onScopeClick = (roleScope, isChecked) => {
    if (isChecked) {
      const updatedEmployeeRolePermissions = [...employeeRolePermissions];
      updatedEmployeeRolePermissions.splice(
        updatedEmployeeRolePermissions.findIndex((p) => p.id === roleScope.id),
        1
      );
      setEmployeeRolePermissions(updatedEmployeeRolePermissions);
    } else {
      setEmployeeRolePermissions([...employeeRolePermissions, roleScope]);
    }
  };

  const isAdmin = sharedHelper.userMeetsRole(authContext.userData, [
    IS_ADMIN_USER,
  ]);

  const isPerDiemEnabled = sharedHelper.isPackageEnabled(
    authContext.userData?.packages,
    PER_DIEM_PACKAGE
  );

  const onDeletePerDiemRule = (index) => {
    setConfirmationModal({
      isOpen: true,
      confirmColor: "danger",
      onSubmit: async () => {
        setConfirmationModal();
        employeeRoleData.employeeRolePerDiemRules.splice(index, 1);
        setEmployeeRoleData({
          ...employeeRoleData,
        });
      },
      onClose: () => {
        setConfirmationModal();
      },
      title: "Delete Per Diem Rule",
      body: `<span class="text-center">Do you confirm you want to delete this rule?</span>`,
    });
  };

  /*
  const isRuleActive = (perDiemRule) => {
    return (
      !perDiemRule.startDate ||
      !perDiemRule.endDate ||
      moment().isBetween(
        moment(perDiemRule.startDate),
        moment(perDiemRule.endDate),
        "[]"
      )
    );
  };
  */

  return editPerDiemRulesModal ? (
    <EditPerDiemRulesModal
      defaultPerDiemRule={editPerDiemRulesModal}
      onSubmit={(perDiemRule) => {
        perDiemRule.employeeRoleId = employeeRoleId;
        setEditPerDiemRulesModal();
        if (perDiemRule.index !== undefined) {
          employeeRoleData.employeeRolePerDiemRules.splice(
            perDiemRule.index,
            1,
            perDiemRule
          );
        } else {
          employeeRoleData.employeeRolePerDiemRules.push(perDiemRule);
        }
        setEmployeeRoleData({
          ...employeeRoleData,
        });
      }}
      onClose={() => setEditPerDiemRulesModal()}
    />
  ) : confirmationModal ? (
    <ConfirmationModal {...confirmationModal} />
  ) : (
    <Modal isOpen={true} size="sm">
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          toggle={onClose}
        >
          {employeeRoleId ? "Edit" : "Create"} Employee Role
        </ModalHeader>
        <ModalBody>
          {isLoadingGetEmployeeRoleScopes ||
          isLoadingCreateEmployeeRole ||
          isLoadingEmployeeRoles ||
          isLoadingUpdateEmployeeRole ? (
            <Loader size="sm" />
          ) : (
            <>
              <FormGroup>
                <Label>
                  <span>Name</span>
                  <span className="text-danger ms-1">*</span>
                </Label>
                <input
                  className="form-control-redesign"
                  maxLength="50"
                  type="text"
                  placeholder="Enter the name"
                  name="name"
                  value={employeeRoleData.name || ""}
                  onChange={(e) =>
                    setEmployeeRoleData({
                      ...employeeRoleData,
                      name: e.target.value,
                    })
                  }
                  required
                />
              </FormGroup>
              <FormGroup className="mb-0">
                <Label>
                  <span>Status</span>
                  <span className="text-danger ms-1">*</span>
                </Label>
                <select
                  className="form-control-redesign"
                  type="select"
                  name="statusSelect"
                  id="statusSelect"
                  onChange={(e) => {
                    setEmployeeRoleData({
                      ...employeeRoleData,
                      isActive: e.target.value === "true",
                    });
                  }}
                  value={employeeRoleData.isActive ? "true" : "false"}
                  required
                >
                  <option value={true}>Active</option>
                  <option value={false}>Inactive</option>
                </select>
              </FormGroup>
              {isAdmin ? (
                <>
                  <FormGroup>
                    <ListGroup>
                      <ListGroupItem className="d-flex justify-content-center align-items-center fw-bold bg-graylight">
                        Permissions
                      </ListGroupItem>
                      {availableRoleScopes.map((roleScope) => {
                        const isChecked = Boolean(
                          employeeRolePermissions.find(
                            (scope) => scope.id === roleScope.id
                          )
                        );
                        return (
                          <ListGroupItem
                            key={roleScope.id}
                            className="d-flex justify-content-between align-items-center cursor-pointer"
                            onClick={() => onScopeClick(roleScope, isChecked)}
                          >
                            <div className="flex-shrink-0">
                              {roleScope.name}
                            </div>
                            <div className="min-width-50 d-flex justify-content-end">
                              <CustomCheckbox checked={isChecked} />
                            </div>
                          </ListGroupItem>
                        );
                      })}
                    </ListGroup>
                  </FormGroup>
                  {isPerDiemEnabled ? (
                    <FormGroup>
                      <ListGroup>
                        <ListGroupItem className="d-flex justify-content-between align-items-center fw-bold bg-graylight">
                          <span>Per Diem Rules</span>
                          <Button
                            color="primary"
                            size="sm"
                            onClick={() => setEditPerDiemRulesModal(true)}
                          >
                            Create
                          </Button>
                        </ListGroupItem>
                        {employeeRoleData.employeeRolePerDiemRules.length ? (
                          employeeRoleData.employeeRolePerDiemRules
                            .sort((x, y) => x.precedence - y.precedence)
                            .map((perDiemRule, index) => (
                              <ListGroupItem
                                key={index}
                                className="d-flex justify-content-between align-items-center cursor-pointer"
                              >
                                <span>{`${perDiemRule.precedence} - ${perDiemRule.name}`}</span>
                                <div className="d-flex align-items-center justify-content-end">
                                  <Button
                                    size="sm"
                                    color="primary"
                                    onClick={() =>
                                      setEditPerDiemRulesModal({
                                        ...perDiemRule,
                                        index,
                                      })
                                    }
                                  >
                                    Edit
                                  </Button>
                                  <Button
                                    color="danger"
                                    className="ms-2"
                                    size="sm"
                                    onClick={() => onDeletePerDiemRule(index)}
                                  >
                                    Delete
                                  </Button>
                                </div>
                              </ListGroupItem>
                            ))
                        ) : (
                          <ListGroupItem className="d-flex justify-content-center align-items-center cursor-pointer">
                            No rules added
                          </ListGroupItem>
                        )}
                      </ListGroup>
                    </FormGroup>
                  ) : null}
                </>
              ) : null}
            </>
          )}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={onClose} className="text-dark">
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Save
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default EmployeeRoleModal;
