import React, { useCallback, useEffect, useState } from "react";

import { Card, CardHeader, CardBody, Container, Button } from "reactstrap";

import { useNavigate } from "react-router-dom";

import { components, sharedHelper, data } from "@crewos/shared";

import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGetCustomers, useDeleteCustomer } from "../api/Customers.hooks";
import CustomerModal from "../components/CustomerModal";

import { DebounceInput } from "react-debounce-input";

const { useAuth } = data;

const { AdvanceTable, AdvanceTablePagination, ConfirmationModal } = components;

const columns = (onSeeDetails, onEdit, onDelete) => [
  {
    accessor: "customerName",
    header: "Name",
    headerProps: { className: "text-truncate", style: { minWidth: 300 } },
    Cell: (rowData) => {
      const { customerName } = rowData.row;
      return customerName || "-";
    },
  },
  {
    accessor: "customerNo",
    header: "Customer No.",
    headerProps: { className: "text-truncate text-center" },
    cellProps: {
      className: "text-center",
    },
    Cell: (rowData) => {
      const { customerNo } = rowData.row;
      return customerNo || "-";
    },
  },
  {
    accessor: "locations",
    header: "Locations",
    disableSortBy: true,
    headerProps: { className: "text-truncate text-center" },
    cellProps: {
      className: "text-center",
    },
    Cell: (rowData) => {
      const { locations } = rowData.row;
      return locations.length;
    },
  },
  {
    accessor: "id",
    header: "",
    disableFilter: true,
    disableSortBy: true,
    headerProps: { style: { minWidth: 300 } },
    cellProps: {
      className: "text-end",
    },
    Cell: (rowData) => (
      <div>
        <Button
          size="sm"
          color="primary"
          onClick={() => onSeeDetails(rowData.row.id)}
        >
          View Details
        </Button>
        <Button
          size="sm"
          color="none"
          className="mx-2 text-primary"
          onClick={() => onEdit(rowData.row)}
        >
          Edit
        </Button>
        {onDelete ? (
          <Button
            size="sm"
            color="none"
            className="text-danger"
            onClick={() => onDelete(rowData.row)}
          >
            Delete
          </Button>
        ) : null}
      </div>
    ),
  },
];

const INIT_PAGE_SIZE = 15;
const INIT_PAGE = 1;

const IS_SUPER_ADMIN_USER = "IS_SUPER_ADMIN_USER";

const Customers = () => {
  const [authContext] = useAuth();
  const [customers, setCustomers] = useState({});
  const [sizePerPage, setSizePerPage] = useState(INIT_PAGE_SIZE);
  const [page, setPage] = useState(INIT_PAGE);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("customerName");
  const [direction, setDirection] = useState("asc");
  const [refresh, setRefresh] = useState();

  const navigate = useNavigate();

  const [editModal, setEditModal] = useState();
  const [createModal, setCreateModal] = useState();

  const [showInactive, setShowInactive] = useState();

  const [confirmationModal, setConfirmationModal] = useState();

  const {
    data: customersData,
    isLoading: isLoadingCustomers,
    get: getCustomers,
  } = useGetCustomers();

  const { data: deleteCustomerData, deleteCustomer } = useDeleteCustomer();

  const onSort = useCallback(
    ([data]) => {
      if (data) {
        if (data.sortBy === sortBy && data.direction === direction) {
          return;
        }
        setSortBy(data.sortBy);
        setDirection(data.direction);
        setPage(INIT_PAGE);
      } else {
        setSortBy(null);
        setDirection(null);
        setPage(INIT_PAGE);
      }
    },
    [setSortBy, setDirection, setPage, direction, sortBy]
  );

  useEffect(() => {
    if (deleteCustomerData) {
      sharedHelper.successToast(`Customer deleted`);
      setRefresh((prev) => !prev);
    }
  }, [deleteCustomerData, setRefresh]);

  const onEdit = (customer) => setEditModal(customer);

  const onDelete = (customer) => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        setConfirmationModal();
        deleteCustomer(customer.id);
      },
      onClose: () => {
        setConfirmationModal();
        setRefresh((prev) => !prev);
      },
      title: "Delete Customer",
      body: `Are you sure you want to delete ${customer.customerName}?`,
      confirmColor: "danger",
    });
  };

  const onSeeDetails = (id) => navigate(`/customers/details/${id}`);

  useEffect(() => {
    getCustomers({
      search: search,
      page: page - 1,
      pageSize: sizePerPage,
      sortBy,
      direction,
      isActive: showInactive ? "false" : "true",
    });
  }, [
    getCustomers,
    sortBy,
    direction,
    sizePerPage,
    page,
    search,
    refresh,
    showInactive,
  ]);

  useEffect(() => {
    if (customersData) {
      setCustomers(customersData);
    }
  }, [customersData, setCustomers]);

  const isSuperAdmin = sharedHelper.userMeetsRole(authContext.userData, [
    IS_SUPER_ADMIN_USER,
  ]);

  return (
    <Container fluid>
      <div className="w-100">
        <Card className="mb-3 w-100 box-shadow-none">
          <CardHeader className="pt-2 pb-3 d-flex">
            <div className="text-dark flex-grow-1 d-flex align-items-center">
              <h2 className="mb-0 ">Customers</h2>
              <small className="text-muted ms-2 pt-1">
                ({customers.count})
              </small>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <div className="me-3">
                <select
                  className="form-control-redesign"
                  type="select"
                  name="activeSelect"
                  id="activeSelect"
                  onChange={(evt) =>
                    setShowInactive(evt.currentTarget.value === "true")
                  }
                  value={showInactive}
                >
                  <option value={false}>Active</option>
                  <option value={true}>Inactive</option>
                </select>
              </div>
              <div className="me-3">
                <DebounceInput
                  className="search border-0 form-control"
                  maxLength={50}
                  minLength={1}
                  debounceTimeout={900}
                  placeholder="Search for.."
                  value={search}
                  onChange={(evt) => {
                    setSearch(evt.target.value);
                    setPage(INIT_PAGE);
                  }}
                />
              </div>
              <Button
                size="sm"
                className="me-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
                color="white"
                onClick={() => setRefresh((prev) => !prev)}
              >
                <FontAwesomeIcon icon={faSync} />
              </Button>
              <div className="table-export-container me-3">
                <div id="table-export" />
              </div>
              <Button color="primary" onClick={() => setCreateModal(true)}>
                Create
              </Button>
            </div>
          </CardHeader>
          <CardBody className="p-4">
            <div className="overflow-x-auto">
              <AdvanceTable
                isLoading={isLoadingCustomers}
                headerClassName="text-muted small"
                columns={columns(
                  onSeeDetails,
                  onEdit,
                  isSuperAdmin ? onDelete : false
                )}
                data={customers.data || []}
                pageSize={sizePerPage}
                sortable
                onSort={onSort}
                exportable
                exportName="customers.csv"
                defaultSort={{
                  sortBy,
                  direction,
                }}
                tableProps={{
                  striped: true,
                }}
              />
            </div>
            <AdvanceTablePagination
              totalCount={customers.count}
              pageCount={customers.totalPages}
              currentPage={page - 1}
              onPageChange={(page) => setPage(page)}
              pageSize={sizePerPage}
              onPageSizeChange={(sizePerPage) => {
                setSizePerPage(sizePerPage);
                setPage(INIT_PAGE);
              }}
            />
          </CardBody>
        </Card>
      </div>
      {editModal ? (
        <CustomerModal
          customer={editModal}
          onClose={() => setEditModal(false)}
          onSubmit={() => {
            setEditModal(false);
            setRefresh((prev) => !prev);
          }}
        />
      ) : createModal ? (
        <CustomerModal
          onClose={() => setCreateModal(false)}
          onSubmit={() => {
            setCreateModal(false);
            setRefresh((prev) => !prev);
          }}
        />
      ) : confirmationModal ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
    </Container>
  );
};

export default Customers;
