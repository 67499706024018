import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormGroup,
  Label,
  Row,
  Form,
} from "reactstrap";

import Select from "react-select";

import { components, sharedHelper } from "@crewos/shared";
import { useGetEquipmentOptions } from "../api/EquipmentOptions.hooks";
import { useCreateEquipment } from "../api/Equipments.hooks";
const { Loader } = components;

const style = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: "none",
  }),
};

const AddEquipmentModal = ({
  onClose,
  onSubmit,
  equipments,
  crewWorkDayId,
}) => {
  const [equipmentOptionInput, setEquipmentOptionInput] = useState();
  const [equipmentOptionsAvailables, setEquipmentOptionsAvailables] = useState(
    []
  );
  const [quantityInput, setQuantityInput] = useState(1);

  const {
    data: equipmentOptions,
    isLoading: isLoadingEquipmentOptions,
    get: getEquipmentOptions,
  } = useGetEquipmentOptions();

  useEffect(() => {
    getEquipmentOptions();
  }, [getEquipmentOptions]);

  useEffect(() => {
    if (equipmentOptions) {
      const remainingEquipmentOptions = equipmentOptions.data.filter(
        (currentEquipmentOption) =>
          !equipments.find(
            (equipment) =>
              currentEquipmentOption.id === equipment.equipmentOption.id
          )
      );
      if (!remainingEquipmentOptions.length) {
        sharedHelper.warningToast("No equipment available to add to this day");
      } else {
        setEquipmentOptionsAvailables(remainingEquipmentOptions);
      }
    }
  }, [equipmentOptions, equipments, onClose]);

  const {
    isLoading: isLoadingCreateEquipment,
    mutate: createEquipment,
    data: createEquipmentData,
  } = useCreateEquipment();

  useEffect(() => {
    if (createEquipmentData) {
      sharedHelper.successToast(`Equipment added`);
      onSubmit();
    }
  }, [createEquipmentData, onSubmit]);

  const doSubmit = async (e) => {
    e.preventDefault();
    await createEquipment({
      crewWorkDayId,
      equipmentOptionId: equipmentOptionInput.value,
      quantity: quantityInput,
    });
  };

  return (
    <Modal isOpen={true} onClosed={onClose} size="sm">
      <ModalHeader toggle={onClose} className="d-flex justify-content-between">
        Add Equipment
      </ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody>
          {isLoadingEquipmentOptions || isLoadingCreateEquipment ? (
            <Loader size="sm" />
          ) : (
            <Row className="mb-2">
              <FormGroup className="col-12 d-flex flex-column">
                <Label>
                  <span>Equipment</span>
                  <small className="text-danger ms-1">*</small>
                </Label>
                <Select
                  placeholder="Search for an equipment.."
                  styles={style}
                  classNamePrefix="react-select"
                  className="text-start bg-white px-0 react-select"
                  options={equipmentOptionsAvailables.map((option) => ({
                    value: option.id,
                    label: option.name,
                  }))}
                  closeMenuOnSelect={true}
                  value={equipmentOptionInput}
                  onChange={(e) => setEquipmentOptionInput(e)}
                  required={true}
                />
              </FormGroup>
              <FormGroup className="col-12 d-flex flex-column">
                <Label>
                  <span>Quantity</span>
                  <small className="text-danger ms-1">*</small>
                </Label>
                <input
                  className="form-control-redesign"
                  placeholder="Enter quantity..."
                  type="number"
                  step={1}
                  max={9999999999}
                  min={1}
                  required={true}
                  disabled={!equipmentOptionInput}
                  value={quantityInput}
                  onChange={(evt) => setQuantityInput(evt.target.value)}
                />
              </FormGroup>
            </Row>
          )}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={onClose} className="text-dark">
            Cancel
          </Button>{" "}
          <Button color="primary" type="submit">
            Confirm
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AddEquipmentModal;
