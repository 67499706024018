import React from "react";
import { Card, Container, CardHeader, Button } from "reactstrap";
import { DebounceInput } from "react-debounce-input";

import { useListTemplateLogic, filters } from "./ListTemplate.logic";

import TemplatesList from "../../../components/TemplatesList";

import { components } from "@crewos/shared";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const { StatusFilter } = components;

const ListTemplates = () => {
  const {
    search,
    selectedFilter,
    templatesGrouped,
    handleRefresh,
    handleSelectFilter,
    handleDeleteItem,
    handleCopyItem,
    handleSearch,
  } = useListTemplateLogic();

  return (
    <Container fluid className="inspections">
      <div className="w-100">
        <Card className="mb-0 w-100 box-shadow-none">
          <CardHeader className="pt-2 pb-3 d-flex mt-n1">
            <div className="text-dark flex-grow-1 d-flex align-items-center">
              <h2 className="mb-0 ">Inspection Templates</h2>
              <small className="text-muted ms-2 pt-1">
                ({templatesGrouped.flatMap((t) => t.templates).length})
              </small>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <div className="me-3">
                <DebounceInput
                  className="search border-0 form-control"
                  maxLength={50}
                  minLength={1}
                  debounceTimeout={900}
                  placeholder="Search"
                  value={search}
                  onChange={handleSearch}
                />
              </div>
              <div className="d-flex align-items-center position-relative">
                <span className="flex-shrink-0">Filter by status:</span>
                <StatusFilter
                  statuses={filters}
                  selectedStatus={selectedFilter}
                  setSelectedStatus={handleSelectFilter}
                />
                <Button
                  size="sm"
                  className="ms-1 rounded-circle d-flex custom-rounded-button text-primary py-2"
                  color="white"
                  data-testid="refresh-button"
                  onClick={handleRefresh}
                >
                  <FontAwesomeIcon icon={faSync} />
                </Button>
              </div>
            </div>
          </CardHeader>
        </Card>
        {templatesGrouped.map((group) => (
          <TemplatesList
            key={group.title}
            title={group.title}
            templates={group.templates}
            onDeleteItem={handleDeleteItem}
            onCopyItem={handleCopyItem}
          />
        ))}
      </div>
    </Container>
  );
};

export default ListTemplates;
