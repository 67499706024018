import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  FormGroup,
  ListGroup,
  ListGroupItem,
  Label,
  Form,
} from "reactstrap";
import {
  useCreateWorkTimeType,
  useUpdateWorkTimeType,
} from "../api/WorkTimeTypes.hooks";
import { components, sharedHelper } from "@crewos/shared";
import { useGetEmployeeRoles } from "@crewos/employees";

const { Loader, CustomCheckbox, SelectAdvance } = components;

const WorkTimeTypeModal = ({ workTimeType = {}, onSubmit, onClose }) => {
  // State variables for form fields
  const [shortName, setShortName] = useState(workTimeType.shortName || "");
  const [name, setName] = useState(workTimeType.name || "");
  const [order, setOrder] = useState(workTimeType.order || 1);
  const [isBulkEnabled, setIsBulkEnabled] = useState(
    workTimeType.isBulkEnabled || false
  );
  const [isAppEnabled, setIsAppEnabled] = useState(
    workTimeType.isAppEnabled || false
  );

  // State for selected employee roles
  const [employeeRoles, setEmployeeRoles] = useState(
    (workTimeType.employeeRoleWorkTimeTypes || []).map(
      (employeeRoleWorkTimeType) => ({
        label: employeeRoleWorkTimeType.employeeRole.name,
        value: employeeRoleWorkTimeType.employeeRoleId,
      })
    )
  );

  // Handler for selecting employee roles
  const handleSelectEmployeeRole = useCallback((selectedEmployeeRoles) => {
    setEmployeeRoles(selectedEmployeeRoles);
  }, []);

  // Hooks for creating and updating work time types
  const {
    isLoading: isLoadingCreateWorkTimeType,
    mutate: createWorkTimeType,
    data: createWorkTimeTypeData,
  } = useCreateWorkTimeType();

  const {
    isLoading: isLoadingUpdateWorkTimeType,
    update: updateWorkTimeType,
    data: updateWorkTimeTypeData,
  } = useUpdateWorkTimeType();

  // Effect to handle successful creation
  useEffect(() => {
    if (createWorkTimeTypeData) {
      sharedHelper.successToast(`Work time type created`);
      onSubmit();
    }
  }, [createWorkTimeTypeData, onSubmit]);

  // Effect to handle successful update
  useEffect(() => {
    if (updateWorkTimeTypeData) {
      sharedHelper.successToast(`Work time type saved`);
      onSubmit();
    }
  }, [updateWorkTimeTypeData, onSubmit]);

  // Handler for form submission
  const doSubmit = async (event) => {
    event.preventDefault();
    const payload = {
      name,
      shortName,
      order,
      isBulkEnabled: isBulkEnabled || false,
      isAppEnabled: isAppEnabled || false,
      employeeRoles: employeeRoles.map((role) => role.value),
    };

    if (workTimeType.id) {
      await updateWorkTimeType({
        id: workTimeType.id,
        ...payload,
      });
    } else {
      await createWorkTimeType(payload);
    }
  };

  // Fetching employee roles using the custom hook
  const {
    data: employeeRolesData,
    isLoading: isLoadingEmployeeRoles,
    get: fetchEmployeeRoles,
  } = useGetEmployeeRoles();

  // Fetch employee roles on component mount
  useEffect(() => {
    fetchEmployeeRoles({ search: "", pageSize: 100 }); // Adjust pageSize as needed
  }, [fetchEmployeeRoles]);

  // Handler for searching employee roles
  const handleSearchEmployeeRoles = useCallback(
    (searchText) => {
      fetchEmployeeRoles({ search: searchText, pageSize: 100 });
    },
    [fetchEmployeeRoles]
  );

  // Transform employee roles data into options for SelectAdvance
  const employeeRoleOptions = useMemo(() => {
    if (employeeRolesData && employeeRolesData.data) {
      return employeeRolesData.data.map((role) => ({
        value: role.id,
        label: role.name,
      }));
    }
    return [];
  }, [employeeRolesData]);

  return (
    <Modal isOpen={true} toggle={onClose}>
      <ModalHeader toggle={onClose} className="d-flex justify-content-between">
        {workTimeType.id ? "Edit Work Time Type" : "Add Work Time Type"}
      </ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody>
          {(isLoadingCreateWorkTimeType || isLoadingUpdateWorkTimeType) && (
            <Loader size="sm" />
          )}
          {!isLoadingCreateWorkTimeType && !isLoadingUpdateWorkTimeType && (
            <>
              {/* Name Field */}
              <FormGroup>
                <Label>
                  <span>Name</span>
                  <span className="text-danger ms-1">*</span>
                </Label>
                <input
                  className="form-control-redesign"
                  maxLength="50"
                  type="text"
                  placeholder="Enter the name"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </FormGroup>

              {/* Short Name Field */}
              <FormGroup>
                <Label>
                  <span>Short Name</span>
                  <span className="text-danger ms-1">*</span>
                </Label>
                <input
                  className="form-control-redesign"
                  maxLength="50"
                  type="text"
                  placeholder="Enter the short name"
                  name="shortName"
                  value={shortName}
                  onChange={(e) => setShortName(e.target.value)}
                  required
                />
              </FormGroup>

              {/* Order Field */}
              <FormGroup className="mb-0">
                <Label>
                  <span>Order</span>
                  <span className="text-danger ms-1">*</span>
                </Label>
                <input
                  className="form-control-redesign"
                  max="999"
                  min="1"
                  type="number"
                  placeholder="Enter the order"
                  name="order"
                  value={order}
                  onChange={(e) => setOrder(e.target.value)}
                  required
                />
              </FormGroup>

              {/* Employee Roles Select */}
              <FormGroup className="mb-0">
                <Label>
                  <span>Employee Roles</span>
                  <small className="ms-2 text-muted">
                    ({employeeRoles.length})
                  </small>
                </Label>
                <SelectAdvance
                  entity="employeeRole"
                  name="employeeRole"
                  className="form-control-redesign height-auto"
                  placeholder="Search an employee role..."
                  noOptionsMessage="No employee roles found"
                  options={employeeRoleOptions} // Pass transformed options
                  isLoading={isLoadingEmployeeRoles} // Pass loading state
                  onSelected={handleSelectEmployeeRole} // Pass selection handler
                  onSearch={handleSearchEmployeeRoles} // Pass search handler
                  labelKey="name"
                  isMulti={true}
                  maxElements={Number.MAX_SAFE_INTEGER}
                  selected={employeeRoles} // Pass selected roles
                />
              </FormGroup>

              {/* Bulk Enabled Checkbox */}
              <FormGroup>
                <ListGroup className="mt-4">
                  <ListGroupItem
                    className="d-flex justify-content-between align-items-center cursor-pointer"
                    onClick={() => setIsBulkEnabled(!isBulkEnabled)}
                  >
                    <div className="flex-shrink-0">Bulk Enabled</div>
                    <div className="min-width-50 d-flex justify-content-end">
                      <CustomCheckbox checked={isBulkEnabled} />
                    </div>
                  </ListGroupItem>
                </ListGroup>
              </FormGroup>

              {/* App Enabled Checkbox */}
              <FormGroup>
                <ListGroup className="mt-4">
                  <ListGroupItem
                    className="d-flex justify-content-between align-items-center cursor-pointer"
                    onClick={() => setIsAppEnabled(!isAppEnabled)}
                  >
                    <div className="flex-shrink-0">App Enabled</div>
                    <div className="min-width-50 d-flex justify-content-end">
                      <CustomCheckbox checked={isAppEnabled} />
                    </div>
                  </ListGroupItem>
                </ListGroup>
              </FormGroup>
            </>
          )}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={onClose} className="text-dark">
            Cancel
          </Button>{" "}
          {isLoadingCreateWorkTimeType || isLoadingUpdateWorkTimeType ? (
            <div className="min-width-50">
              <Loader size="sm" />
            </div>
          ) : (
            <Button color="primary" type="submit">
              Confirm
            </Button>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default WorkTimeTypeModal;
