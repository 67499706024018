import React, { useEffect, useState } from "react";

import { Button, Card, CardBody } from "reactstrap";

import { faTrash } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { components, sharedHelper } from "@crewos/shared";

import { useDeleteEquipment } from "../api/Equipments.hooks";
import EquipmentQuantityModal from "./EquipmentQuantityModal";

const { ConfirmationModal } = components;

const Equipment = ({ equipment, readOnly = false, onRefresh }) => {
  const [equipmentModal, setEquipmentModal] = useState(false);

  const [confirmationModal, setConfirmationModal] = useState();

  const { data: deleteEquipmentData, deleteEquipment } = useDeleteEquipment();

  useEffect(() => {
    if (deleteEquipmentData) {
      sharedHelper.successToast(`Equipment deleted`);
      onRefresh();
    }
  }, [deleteEquipmentData, onRefresh]);

  const onEdit = (equipment, readOnly) =>
    setEquipmentModal({ ...equipment, readOnly });

  const onDelete = (equipment) => {
    setConfirmationModal({
      isOpen: true,
      confirmColor: "danger",
      onSubmit: () => {
        setConfirmationModal();
        deleteEquipment(equipment.id);
      },
      onClose: () => {
        setConfirmationModal();
      },
      title: "Delete Equipment",
      body: `Are you sure you want to delete ${
        equipment.equipmentOption?.name || "N/A"
      } from this work day?`,
    });
  };

  return (
    <div key={equipment.id} className="col-12">
      <Card className="mb-0 box-shadow-darker">
        <CardBody>
          <div className="d-flex align-items-center justify-content-between">
            <h4 className="currency mb-0">{equipment.quantity}</h4>
            <small className="text-muted">
              {equipment.equipmentOption?.name || "N/A"}
            </small>
          </div>
          {!readOnly ? (
            <div className="mt-3 d-flex">
              <Button
                className="cursor-pointer border-radius-md"
                onClick={() => onDelete(equipment)}
                data-testid="delete-equipment"
              >
                <FontAwesomeIcon
                  size="sm"
                  icon={faTrash}
                  className="text-danger"
                />
              </Button>
              <Button
                color="primary"
                className="cursor-pointer ms-3 flex-grow-1"
                onClick={() => onEdit(equipment)}
              >
                Edit
              </Button>
            </div>
          ) : null}
        </CardBody>
      </Card>
      {confirmationModal ? (
        <ConfirmationModal {...confirmationModal} />
      ) : equipmentModal ? (
        <EquipmentQuantityModal
          equipment={equipmentModal}
          onSubmit={() => {
            setEquipmentModal(false);
            sharedHelper.successToast(`Equipment updated`);
            onRefresh();
          }}
          onClose={() => setEquipmentModal(false)}
        />
      ) : null}
    </div>
  );
};

export default Equipment;
