import React, { useState, useCallback } from "react";

import { components, sharedHelper, data } from "@crewos/shared";

import { useNavigate } from "react-router-dom";

import moment from "moment";

import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from "reactstrap";
import { EditWorkTimeModal } from "@crewos/worktimes";
import { EditTravelTimeModal } from "@crewos/traveltimes";

const dateFormat = "MM/DD";

const TRAVEL_TIMES_PACKAGE = "traveltimes";
const WORK_TIMES_PACKAGE = "worktimes";

const START_END_TRAVEL_TIME_SETTING = "START_END_TRAVEL_TIME_SETTING";
const START_END_WORK_TIME_SETTING = "START_END_WORK_TIME_SETTING";

const { AdvanceTable } = components;
const { useAuth } = data;

const PayrollReportDateDetail = ({ entry, date, onClose }) => {
  const [authContext] = useAuth();
  const navigate = useNavigate();

  const onSeeWODetails = (workOrderId) =>
    navigate(`/workorders/details/${workOrderId}`);

  const onSeeCustomerDetails = (customerId) =>
    navigate(`/customers/details/${customerId}`);

  const [touched, setTouched] = useState();
  const [localEntry, setLocalEntry] = useState(entry);
  const [editTravelTimeModal, setEditTravelTimeModal] = useState();
  const [editWorkTimeModal, setEditWorkTimeModal] = useState();

  const startEndTimeSettingEnabled =
    sharedHelper.isSettingEnabled(
      authContext.userData?.packages,
      TRAVEL_TIMES_PACKAGE,
      START_END_TRAVEL_TIME_SETTING
    ) ||
    sharedHelper.isSettingEnabled(
      authContext.userData?.packages,
      WORK_TIMES_PACKAGE,
      START_END_WORK_TIME_SETTING
    );

  const dateData = date
    ? localEntry[date]
    : {
      hours: Object.values(localEntry)
        .filter((e) => typeof e === "object")
        .reduce((p, c) => p + c.hours, 0),
      rawTimes: Object.values(localEntry)
        .filter((e) => typeof e === "object")
        .reduce((p, c) => [...p, ...c.rawTimes], []),
    };

  const { hours, rawTimes } = dateData;

  const onEditEntry = (entry) => {
    const time = rawTimes.find((time) => time.id === entry.Id);
    if (time.type === "Travel") {
      setEditTravelTimeModal(time);
    } else {
      setEditWorkTimeModal(time);
    }
  };

  const totalColumns = [
    {
      accessor: "Total Hours",
      header: "Total Hours",
      headerProps: { className: date ? "text-end" : "text-center" },
      cellProps: { className: date ? "text-end" : "text-center" },
      type: "number",
      Cell: (rowData) => {
        return rowData.row["Total Hours"];
      },
    },
  ];

  const detailColumns = [
    {
      accessor: "Work Order",
      header: "Work Order",
      headerProps: { className: "text-start" },
      cellProps: { className: "text-start" },
      Cell: (rowData) => {
        return (
          <span
            className="text-link"
            data-testid="work-order-link"
            onClick={() => onSeeWODetails(rowData.row["Work Order Id"])}
          >
            {rowData.row["Work Order"]}
          </span>
        );
      },
    },
    {
      accessor: "Customer",
      header: "Customer",
      headerProps: { className: "text-center" },
      cellProps: { className: "text-center" },
      Cell: (rowData) => {
        return (
          <span
            className="text-link"
            data-testid="customer-link"
            onClick={() => onSeeCustomerDetails(rowData.row["CustomerId"])}
          >
            {rowData.row["Customer"]}
          </span>
        );
      },
    },
    {
      accessor: "Type",
      header: "Type",
      headerProps: { className: "text-center" },
      cellProps: { className: "text-center" },
      Cell: (rowData) => {
        return rowData.row["Type"];
      },
    },
  ];

  if (startEndTimeSettingEnabled) {
    detailColumns.push({
      accessor: "Start Time",
      header: "Start Time",
      headerProps: { className: "text-center" },
      cellProps: { className: "text-center" },
      type: "string",
      Cell: (rowData) => {
        const startTime = rowData.row["Start Time"];
        return (
          <span className="text-link" onClick={() => onEditEntry(rowData.row)}>
            {moment(startTime).isValid()
              ? sharedHelper.formatDateTime(startTime, "MM/DD, h:mm a")
              : "-"}
          </span>
        );
      },
    });
    detailColumns.push({
      accessor: "End Time",
      header: "End Time",
      headerProps: { className: "text-center" },
      cellProps: { className: "text-center" },
      type: "string",
      Cell: (rowData) => {
        const endTime = rowData.row["End Time"];
        return (
          <span className="text-link" onClick={() => onEditEntry(rowData.row)}>
            {moment(endTime).isValid()
              ? sharedHelper.formatDateTime(endTime, "MM/DD, h:mm a")
              : endTime}
          </span>
        );
      },
    });
  }

  detailColumns.push({
    accessor: "Hours",
    header: "Hours",
    headerProps: { className: "text-end" },
    cellProps: { className: "text-end" },
    type: "number",
    Cell: (rowData) => {
      return (
        <span className="text-link" onClick={() => onEditEntry(rowData.row)}>
          {rowData.row["End Time"] === "In Progress"
            ? rowData.row["End Time"]
            : rowData.row["Hours"]}
        </span>
      );
    },
  });

  if (date) {
    totalColumns.unshift({
      accessor: "Date",
      header: "Date",
      headerProps: { className: "text-start" },
      cellProps: { className: "text-start" },
      Cell: (rowData) => {
        return rowData.row["Date"];
      },
    });
  } else {
    detailColumns.unshift({
      accessor: "Date",
      header: "Date",
      headerProps: { className: "text-start" },
      cellProps: { className: "text-start" },
      Cell: (rowData) => {
        const date = moment(rowData.row["Date"]);
        return `${date.format("dd")} ${date.format(dateFormat)}`;
      },
    });
  }

  const handleClose = useCallback(() => onClose(touched), [onClose, touched]);

  const onSubmitTimes = (times) => {
    times.forEach((time) => {
      const date = Object.keys(localEntry).find(
        (date) =>
          localEntry[date].rawTimes &&
          localEntry[date].rawTimes.find((rawTime) => rawTime.id === time.id)
      );
      const rawTime = localEntry[date].rawTimes.find(
        (rawTime) => rawTime.id === time.id
      );
      const timeHours = parseFloat(time.hours);
      if (rawTime.hours !== timeHours) {
        rawTime.hours = timeHours;
      } else {
        rawTime.startTime = time.startTime;
        rawTime.endTime = time.endTime;
        const minutes = time.endTime
          ? Math.abs(
            moment(time.endTime).diff(moment(time.startTime), "minutes")
          )
          : 0;
        rawTime.hours = minutes / 60;
      }
      localEntry[date].hours = localEntry[date].rawTimes.reduce(
        (p, c) => p + parseFloat(c.hours),
        0
      );
    });
    setTouched(true);
    setLocalEntry(JSON.parse(JSON.stringify(localEntry)));
  };

  return editWorkTimeModal ? (
    <EditWorkTimeModal
      workTimes={[editWorkTimeModal]}
      employee={editWorkTimeModal.employee}
      onClose={() => {
        setEditWorkTimeModal();
      }}
      onSubmit={(times) => {
        onSubmitTimes(times);
        setEditWorkTimeModal();
      }}
    />
  ) : editTravelTimeModal ? (
    <EditTravelTimeModal
      travelTimes={[editTravelTimeModal]}
      employee={editTravelTimeModal.employee}
      onClose={() => {
        setEditTravelTimeModal();
      }}
      onSubmit={(times) => {
        onSubmitTimes(times);
        setEditTravelTimeModal();
      }}
    />
  ) : (
    <Modal isOpen={true} onClosed={handleClose} size="xl">
      <ModalHeader toggle={handleClose} className="d-flex justify-content-between">
        {localEntry.employee}
      </ModalHeader>
      <ModalBody>
        <AdvanceTable
          exportable
          exportName="PayrollDateTotals.csv"
          columns={totalColumns}
          data={[
            {
              Date: date,
              "Total Hours": hours.toFixed(2),
            },
          ]}
          pageSize={1}
          headerClassName="text-muted small"
          tableProps={{
            striped: true,
          }}
        />
        <AdvanceTable
          className="mt-2"
          exportable
          exportName="PayrollDateDetails.csv"
          columns={detailColumns}
          data={rawTimes.map((rawTime) => {
            const data = {
              Id: rawTime.id,
              Date: rawTime.crewWorkDay.date,
              "Work Order": rawTime.crewWorkDay.crew.workOrder.workOrderNumber,
              "Work Order Id": rawTime.crewWorkDay.crew.workOrder.id,
              Customer:
                rawTime.crewWorkDay.crew.workOrder.customer?.customerName ||
                "-",
              CustomerId:
                rawTime.crewWorkDay.crew.workOrder.customer?.id || "-",
              Type: rawTime.type,
              Hours: (rawTime.hours || 0).toFixed(2),
            };
            if (startEndTimeSettingEnabled) {
              data["Start Time"] = rawTime.startTime || "-";
              data["End Time"] =
                rawTime.endTime || (rawTime.startTime ? "In Progress" : "-");
            }
            return data;
          })}
          pageSize={Math.max(rawTimes.length, 1)}
          headerClassName="text-muted small"
          tableProps={{
            striped: true,
          }}
        />
      </ModalBody>
      <ModalFooter className="justify-content-end">
        <Button color="primary" onClick={() => onClose(touched)}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default PayrollReportDateDetail;
