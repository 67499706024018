import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Container, Card, CardHeader, CardBody, Button } from "reactstrap";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { components } from "@crewos/shared";
import { useGetAllWorkTimeTypes } from "@crewos/worktimes";
import { useGetLaborReport } from "../api/Reports.hooks";

const { AdvanceTable, WeekSelector, CustomPeriodSelector, PeriodTypeSelector } =
  components;

const TYPE_WEEKLY = "TYPE_WEEKLY";

const LaborCostReport = () => {
  const [refresh, setRefresh] = useState(false);
  const [periodType, setPeriodType] = useState({
    value: TYPE_WEEKLY,
    label: "Weekly",
  });
  const [dates, setDates] = useState({
    start: moment().startOf("isoWeek").format("YYYY-MM-DD"),
    end: moment().endOf("isoWeek").format("YYYY-MM-DD"),
  });

  const {
    data: workTimeTypes,
    isLoading: isLoadingWorkTimeTypes,
    get: getAllWorkTimeTypes,
  } = useGetAllWorkTimeTypes();

  const {
    data: reportData,
    isLoading: isLoadingReport,
    get: getLaborReport,
  } = useGetLaborReport();

  // Llamadas a las APIs en useEffect
  useEffect(() => {
    getAllWorkTimeTypes();
  }, [getAllWorkTimeTypes]);

  useEffect(() => {
    getLaborReport({
      startDate: dates.start,
      endDate: dates.end,
    });
  }, [getLaborReport, dates, refresh]);

  // Cambio de tipo de período
  const handleChangePeriodType = useCallback((selectedPeriodType) => {
    setPeriodType((prev) => {
      if (prev.value === selectedPeriodType.value) {
        return prev;
      }
      if (selectedPeriodType.value === TYPE_WEEKLY) {
        setDates((dates) => ({
          start: moment(dates.start).startOf("isoWeek").format("YYYY-MM-DD"),
          end: moment(dates.start).endOf("isoWeek").format("YYYY-MM-DD"),
        }));
      }
      return selectedPeriodType;
    });
  }, []);

  // Columnas de la tabla
  const workTimeTypesColumn = useMemo(() => {
    if (workTimeTypes && !isLoadingWorkTimeTypes) {
      return workTimeTypes.map((type) => ({
        accessor: `workTimes.${type.shortName}.totalHours`,
        header: type.name,
        headerProps: { className: "text-truncate" },
        cellProps: { className: "text-truncate" },
        type: "number",
        Cell: (rowData) => {
          const { workTimes } = rowData.row;
          const key = Object.keys(workTimes).find(
            (key) => key === type.shortName
          );
          return key && workTimes[key]
            ? workTimes[key].totalHours.toFixed(2)
            : "0.00";
        },
      }));
    }
    return [];
  }, [workTimeTypes, isLoadingWorkTimeTypes]);

  const columns = useMemo(() => {
    return [
      {
        accessor: "employee",
        header: "Employee",
        headerProps: { className: "text-truncate" },
        cellProps: { className: "text-truncate" },
        Cell: (rowData) => rowData.row.employee,
      },
      {
        accessor: "workOrderNumber",
        header: "Work Order",
        headerProps: { className: "text-truncate" },
        cellProps: { className: "text-truncate" },
        Cell: (rowData) => rowData.row.workOrderNumber,
      },
      ...workTimeTypesColumn,
    ];
  }, [workTimeTypesColumn]);

  return (
    <Container fluid>
      <div className="w-100">
        <Card className="mb-3 w-100 box-shadow-none">
          <CardHeader className="pt-2 pb-3 d-flex justify-content-between mt-n1">
            <div className="text-dark flex-grow-1 d-flex align-items-center flex-shrink-0">
              <h2 className="mb-0">Labor Cost Report</h2>
            </div>
            <div className="ms-4 d-flex align-items-center justify-content-end flex-shrink-0">
              <PeriodTypeSelector
                periodType={periodType}
                setPeriodType={handleChangePeriodType}
              />
              {periodType.value === TYPE_WEEKLY ? (
                <WeekSelector
                  className="me-3"
                  loading={isLoadingReport}
                  weekStart={dates.start}
                  setWeekStart={(startDate) => {
                    const endDate = moment(startDate)
                      .endOf("isoWeek")
                      .format("YYYY-MM-DD");
                    setDates({
                      start: startDate,
                      end: endDate,
                    });
                  }}
                />
              ) : (
                <CustomPeriodSelector
                  defaultStartDate={dates.start}
                  defaultEndDate={dates.end}
                  onSubmit={(startDate, endDate) => {
                    setDates({
                      start: moment(startDate).format("YYYY-MM-DD"),
                      end: moment(endDate).format("YYYY-MM-DD"),
                    });
                  }}
                />
              )}
              <Button
                size="sm"
                className="me-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
                color="white"
                onClick={() => setRefresh((prev) => !prev)}
                data-testid="refresh-button"
              >
                <FontAwesomeIcon icon={faSync} />
              </Button>
              <div className="table-export-container me-3">
                <div id="table-export" />
              </div>
            </div>
          </CardHeader>
          <CardBody className="p-4 border-radius-default overflow-x-auto">
            <AdvanceTable
              exportable
              exportName="LaborCostReport.csv"
              columns={columns}
              data={reportData || []}
              pageSize={Math.max(reportData?.length, 1)}
              isLoading={isLoadingReport}
              headerClassName="text-muted small"
              tableProps={{
                striped: true,
                className: "mb-0 overflow-hidden w-100",
              }}
            />
          </CardBody>
        </Card>
      </div>
    </Container>
  );
};

export default LaborCostReport;
