import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Button,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

import { faSync } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDeleteUser, useGetUsers } from "../api/Users.hooks";
import UserModal from "../components/UserModal";

import { components, sharedHelper, data } from "@crewos/shared";

import { DebounceInput } from "react-debounce-input";

const { useAuth } = data;

const {
  AdvanceTable,
  AdvanceTablePagination,
  ConfirmationModal,
  InformationModal,
} = components;

const columns = (onEdit, onDelete, onLastActivity) => [
  {
    accessor: "firstName",
    header: "First Name",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) => {
      const { firstName } = rowData.row;
      return firstName || "-";
    },
  },
  {
    accessor: "lastName",
    header: "Last Name",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) => {
      const { lastName } = rowData.row;
      return lastName || "-";
    },
  },
  {
    accessor: "email",
    header: "Email",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) => {
      const { email } = rowData.row;
      return email || "-";
    },
  },
  {
    accessor: "role.name",
    header: "Role",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) => {
      const { role } = rowData.row;
      return role.name || "-";
    },
  },
  {
    accessor: "id",
    header: "",
    disableFilter: true,
    headerProps: { style: { minWidth: 250 } },
    disableSortBy: true,
    cellProps: {
      className: "text-end",
    },
    Cell: (rowData) => (
      <div>
        <Button
          size="sm"
          color="none"
          className="text-primary"
          onClick={() => onLastActivity(rowData.row)}
        >
          Last Activity
        </Button>
        <Button
          size="sm"
          color="none"
          className="mx-2 text-primary"
          onClick={() => onEdit(rowData.row)}
        >
          Edit
        </Button>
        {onDelete ? (
          <Button
            size="sm"
            color="none"
            className="text-danger"
            data-testid="delete-button"
            onClick={() => onDelete(rowData.row)}
          >
            Delete
          </Button>
        ) : null}
      </div>
    ),
  },
];

const INIT_PAGE_SIZE = 15;
const INIT_PAGE = 1;

const IS_SUPER_ADMIN_USER = "IS_SUPER_ADMIN_USER";

const Users = () => {
  const [authContext] = useAuth();
  const [users, setUsers] = useState({});
  const [sizePerPage, setSizePerPage] = useState(INIT_PAGE_SIZE);
  const [page, setPage] = useState(INIT_PAGE);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("firstName");
  const [direction, setDirection] = useState("asc");
  const [refresh, setRefresh] = useState();

  const [editModal, setEditModal] = useState();
  const [createModal, setCreateModal] = useState();

  const [showInactive, setShowInactive] = useState();

  const [confirmationModal, setConfirmationModal] = useState();
  const [informationModal, setInformationModal] = useState();

  const {
    data: usersData,
    isLoading: isLoadingUsers,
    get: getUsers,
  } = useGetUsers();

  const { data: deleteUserData, deleteUser } = useDeleteUser();

  const onSort = useCallback(
    ([data]) => {
      if (data) {
        if (data.sortBy === sortBy && data.direction === direction) {
          return;
        }
        setSortBy(data.sortBy);
        setDirection(data.direction);
        setPage(INIT_PAGE);
      } else {
        setSortBy(null);
        setDirection(null);
        setPage(INIT_PAGE);
      }
    },
    [setSortBy, setDirection, setPage, direction, sortBy]
  );

  useEffect(() => {
    getUsers({
      search: search,
      page: page - 1,
      pageSize: sizePerPage,
      sortBy,
      direction,
      isActive: showInactive ? "false" : "true",
    });
  }, [
    getUsers,
    sortBy,
    direction,
    sizePerPage,
    page,
    search,
    refresh,
    showInactive,
  ]);

  useEffect(() => {
    if (usersData) {
      setUsers(usersData);
    }
  }, [usersData, setUsers]);

  useEffect(() => {
    if (deleteUserData) {
      sharedHelper.successToast(`User deleted`);
      setRefresh((prev) => !prev);
    }
  }, [deleteUserData, setRefresh]);

  const onEdit = (user) => setEditModal(user);

  const onDelete = (user) => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        setConfirmationModal();
        deleteUser(user.id);
      },
      onClose: () => {
        setConfirmationModal();
        setRefresh((prev) => !prev);
      },
      title: "Delete User",
      body: `Are you sure you want to delete ${user.firstName} ${user.lastName}?`,
      confirmColor: "danger",
    });
  };

  const onLastActivity = (user) => {
    const lastActivity = user.lastActivity
      ? user.lastActivity.split(" on ")
      : false;
    let appVersion = user.appVersion || "";
    let isWeb = appVersion.indexOf("WEB") > -1;
    if (isWeb) {
      appVersion = appVersion.replace(" - Not Set", "");
    }
    setInformationModal({
      isOpen: true,
      title: "User Last Activity",
      rawBody: true,
      onClose: () => setInformationModal(),
      body: (
        <ListGroup className="small">
          <ListGroupItem
            className="d-flex justify-content-between align-items-center py-2 bg-graylight font-weight-bold"
            tag="div"
          >
            Platform{!isWeb ? " - Version" : ""}
          </ListGroupItem>
          <ListGroupItem
            className="d-flex justify-content-between align-items-center py-2"
            tag="div"
          >
            <i className="small">{appVersion || "-"}</i>
          </ListGroupItem>
          <ListGroupItem
            className="d-flex justify-content-between align-items-center py-2 bg-graylight font-weight-bold"
            tag="div"
          >
            Activity
          </ListGroupItem>
          <ListGroupItem
            className="d-flex flex-column justify-content-between align-items-start py-2"
            tag="div"
          >
            {lastActivity
              ? lastActivity.map((item, i) => (
                  <i className="small">
                    {i ? sharedHelper.formatDateTime(item) : item}
                  </i>
                ))
              : "-"}
          </ListGroupItem>
        </ListGroup>
      ),
    });
  };

  const isSuperAdmin = sharedHelper.userMeetsRole(authContext.userData, [
    IS_SUPER_ADMIN_USER,
  ]);

  return (
    <Container fluid>
      <div className="w-100">
        <Card className="mb-3 w-100 box-shadow-none">
          <CardHeader className="pt-2 pb-3 d-flex">
            <div className="text-dark flex-grow-1 d-flex align-items-center">
              <h2 className="mb-0 ">Users</h2>
              <small className="text-muted ms-2 pt-1">({users.count})</small>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <div className="me-3">
                <select
                  className="form-control-redesign"
                  type="select"
                  name="activeSelect"
                  id="activeSelect"
                  onChange={(evt) =>
                    setShowInactive(evt.currentTarget.value === "true")
                  }
                  value={showInactive}
                >
                  <option value={false}>Active</option>
                  <option value={true}>Inactive</option>
                </select>
              </div>
              <div className="me-3">
                <DebounceInput
                  className="search border-0 form-control"
                  maxLength={50}
                  minLength={1}
                  debounceTimeout={900}
                  placeholder="Search for.."
                  value={search}
                  onChange={(evt) => {
                    setSearch(evt.target.value);
                    setPage(INIT_PAGE);
                  }}
                />
              </div>
              <Button
                size="sm"
                className="me-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
                color="white"
                onClick={() => setRefresh((prev) => !prev)}
                data-testid="refresh-button"
              >
                <FontAwesomeIcon icon={faSync} />
              </Button>
              <div className="table-export-container me-3">
                <div id="table-export" />
              </div>
              <Button color="primary" onClick={() => setCreateModal(true)}>
                Create
              </Button>
            </div>
          </CardHeader>
          <CardBody className="p-4">
            <div className="overflow-x-auto">
              <AdvanceTable
                exportable
                exportName="Users.csv"
                columns={columns(
                  onEdit,
                  isSuperAdmin ? onDelete : false,
                  onLastActivity
                )}
                data={users.data || []}
                pageSize={sizePerPage}
                sortable
                onSort={onSort}
                defaultSort={{
                  sortBy,
                  direction,
                }}
                isLoading={isLoadingUsers}
                headerClassName="text-muted small"
                tableProps={{
                  striped: true,
                }}
              />
            </div>
            <AdvanceTablePagination
              totalCount={users.count}
              pageCount={users.totalPages}
              currentPage={page - 1}
              onPageChange={(page) => setPage(page)}
              pageSize={sizePerPage}
              onPageSizeChange={(sizePerPage) => {
                setSizePerPage(sizePerPage);
                setPage(INIT_PAGE);
              }}
            />
          </CardBody>
        </Card>
      </div>
      {editModal ? (
        <UserModal
          user={editModal}
          onClose={() => setEditModal(false)}
          onSubmit={() => {
            setEditModal(false);
            setRefresh((prev) => !prev);
          }}
        />
      ) : createModal ? (
        <UserModal
          onClose={() => setCreateModal(false)}
          onSubmit={() => {
            setCreateModal(false);
            setRefresh((prev) => !prev);
          }}
        />
      ) : confirmationModal ? (
        <ConfirmationModal {...confirmationModal} />
      ) : informationModal ? (
        <InformationModal {...informationModal} />
      ) : null}
    </Container>
  );
};

export default Users;
