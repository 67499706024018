// SidebarCategory.js
import React, { useState, useEffect } from "react";
import { Badge, Collapse } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";
import SidebarItem from "./SidebarItem";
import { useAuth } from "../../providers/authProvider";
import { sharedHelper } from "../../helpers/sharedHelper";

const SidebarCategory = ({
  name,
  icon,
  entries,
  path,
  pkg,
  badgeColor,
  badgeText,
}) => {
  const [authContext] = useAuth();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(location.pathname.startsWith(path));

  useEffect(() => {
    setIsOpen(location.pathname.startsWith(path));
  }, [location.pathname, path]);

  const toggle = () => setIsOpen(!isOpen);

  const filteredEntries = entries.filter(
    (entry) =>
      entry.sidebar &&
      (entry.setting
        ? sharedHelper.isSettingEnabled(
            authContext.userData?.packages,
            pkg,
            entry.setting
          )
        : true) &&
      sharedHelper.routeMeetsRole(
        entry,
        authContext.userData?.role.userRoleScopes
      )
  );

  const entriesByCategory = filteredEntries.reduce((p, c) => {
    if (!c.category) {
      c.category = "NOT SET";
    }
    if (!p[c.category]) {
      p[c.category] = [];
    }
    p[c.category].push(c);
    return p;
  }, {});

  return (
    <div
      className={`sidebar-category mb-1 ${isOpen ? "active" : ""}`}
      data-testid="sidebar-category"
    >
      <div
        data-toggle="collapse"
        data-testid="sidebar-link"
        className={
          "sidebar-link d-flex align-items-center " +
          (!isOpen ? "collapsed" : "")
        }
        onClick={toggle}
        aria-expanded={isOpen}
        role="button"
      >
        {icon && (
          <FontAwesomeIcon
            style={{ paddingBottom: 2 }}
            icon={icon}
            fixedWidth
            className={`align-middle mx-2 sidebar-icon ${
              isOpen ? "text-primary" : ""
            }`}
          />
        )}
        <span
          className={`align-middle text-truncate ms-1 ${
            isOpen ? "text-primary" : "text-muted"
          }`}
        >
          {name}
        </span>
        {badgeColor && badgeText && (
          <Badge color={badgeColor} size={18} className="sidebar-badge">
            {badgeText}
          </Badge>
        )}
      </div>
      <Collapse isOpen={isOpen}>
        <div className="sidebar-dropdown list-unstyled d-flex flex-column">
          {Object.keys(entriesByCategory).map((category, index) => {
            const entries = entriesByCategory[category];
            return (
              <div key={index}>
                {category !== "NOT SET" ? (
                  <div className="sidebar-header">{category}</div>
                ) : null}
                {entries.map((entry, index) => (
                  <SidebarItem
                    isLast={index === entries.length - 1}
                    key={index}
                    icon={entry.icon}
                    name={entry.name}
                    path={`${path}/${entry.path}`}
                    action={entry.action}
                    pkg={pkg}
                    setting={entry.setting}
                  />
                ))}
              </div>
            );
          })}
        </div>
      </Collapse>
    </div>
  );
};

export default SidebarCategory;
