import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const CustomCheckbox = ({ onClick, checked }) => {
  return (
    <div
      className="bg-primary border border-primary rounded cursor-pointer px-1"
      onClick={() => (onClick ? onClick() : null)}
    >
      {checked ? (
        <FontAwesomeIcon
          icon={faCheck}
          className="text-white"
          size="sm"
          data-testid="checked-icon"
        />
      ) : (
        <FontAwesomeIcon
          icon={faCheck}
          className="text-primary"
          size="sm"
          data-testid="unchecked-icon"
        />
      )}
    </div>
  );
};

export default CustomCheckbox;
