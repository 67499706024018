import { useCrews } from "@crewos/crews";
import CrewWorkDayTemplatesWO from "./CrewWorkDayTemplatesWO";
import { useGetTemplateWorkOrder } from "../api/Inspections.hooks";
import React, { useCallback, useEffect, useMemo } from "react";
import CrewWorkDayInspectionsWrapper from "./CrewWorkDayInspections";
import { components } from "@crewos/shared";

const { Loader } = components;

const CrewWorkDay = ({
  crewWorkDay,
  isViewPerDay,
  workOrder,
  needsUpdate,
  search,
}) => {
  const [crewsContext] = useCrews();
  const {
    data: templateWOData,
    isLoading: isLoadingTemplateWO,
    get: getTemplateWO,
  } = useGetTemplateWorkOrder();

  const handleGetTemplateWO = useCallback(() => {
    const payload = {
      workOrderId: workOrder.id,
      crewWorkDayId: isViewPerDay ? crewWorkDay.id : undefined,
      search,
      page: 0,
      pageSize: 100,
    };
    getTemplateWO(payload);
  }, [workOrder.id, crewWorkDay.id, isViewPerDay, getTemplateWO, search]);

  useEffect(() => {
    handleGetTemplateWO();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isViewPerDay, needsUpdate, search]);

  const templateWOFiltered = useMemo(() => {
    return templateWOData && templateWOData.data ? templateWOData.data : [];
  }, [templateWOData]);

  const crew = crewsContext.crews.find(
    (crew) => crew.id === crewWorkDay.crewId
  );
  if (!crew) {
    return null;
  }
  if (isLoadingTemplateWO) {
    return <Loader size="sm" />;
  }

  return templateWOFiltered.map((templateWO) => (
    <CrewWorkDayTemplatesWO
      crew={crew}
      key={templateWO.id}
      templateWorkOrder={templateWO}
    >
      <CrewWorkDayInspectionsWrapper
        inspections={templateWO.inspections}
        onInspectionDelete={handleGetTemplateWO}
      />
    </CrewWorkDayTemplatesWO>
  ));
};

export default CrewWorkDay;
