import React, { useEffect, useState } from "react";

import { Card, CardHeader, CardBody, Container, Button } from "reactstrap";

import { faSync } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { components, sharedHelper, data } from "@crewos/shared";

import { DebounceInput } from "react-debounce-input";

import { useGetPayroll } from "../api/Reports.hooks";

import PayrollReportDateDetail from "../components/PayrollReportDateDetail";

import moment from "moment";

const { useAuth } = data;

const dateFormat = "MM/DD";

const TRAVEL_TIMES_PACKAGE = "traveltimes";
const WORK_TIMES_PACKAGE = "worktimes";

const START_END_TRAVEL_TIME_SETTING = "START_END_TRAVEL_TIME_SETTING";
const START_END_WORK_TIME_SETTING = "START_END_WORK_TIME_SETTING";

const { AdvanceTable, WeekSelector } = components;

const getDateLabelWithIncrement = (date, increment = 0) => {
  const incrementDate = moment(date).add(increment, "day");
  return `${incrementDate.format("dd")} ${incrementDate.format(dateFormat)}`;
};

const PayrollReport = () => {
  const [authContext] = useAuth();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [refresh, setRefresh] = useState();
  const [dateDetailsModal, setDateDetailsModal] = useState();

  const { data: reportData, isLoading, get: getPayroll } = useGetPayroll();

  const [weekStart, setWeekStart] = useState(
    moment().startOf("isoWeek").format("YYYY-MM-DD")
  );

  useEffect(() => {
    getPayroll({
      search,
      weekStart,
    });
  }, [getPayroll, refresh, search, weekStart]);

  useEffect(() => {
    if (reportData) {
      setData(reportData);
    }
  }, [reportData, setData]);

  const onSeeDateDetails = (entry, date) =>
    setDateDetailsModal({ entry, date });

  const startEndTimeSettingEnabled =
    sharedHelper.isSettingEnabled(
      authContext.userData?.packages,
      TRAVEL_TIMES_PACKAGE,
      START_END_TRAVEL_TIME_SETTING
    ) ||
    sharedHelper.isSettingEnabled(
      authContext.userData?.packages,
      WORK_TIMES_PACKAGE,
      START_END_WORK_TIME_SETTING
    );

  const weekDays = Array.from(Array(7).keys()).map((c) => {
    const date = getDateLabelWithIncrement(weekStart, c);
    return {
      accessor: `${date}.hours`,
      header: date,
      headerProps: { className: "text-truncate" },
      cellProps: {
        className: "text-truncate",
      },
      type: "number",
      Cell: (rowData) => {
        if (!rowData.row[date]) {
          return <span>0</span>;
        }

        const { hours, rawTimes } = rowData.row[date];

        const hasInProgressTime = rawTimes.find(
          (time) => time.startTime && !time.endTime
        );

        if (rowData.row.isExport) {
          return hasInProgressTime ? "In Progress" : hours.toFixed(2);
        }

        const isLink = hasInProgressTime || hours > 0;

        return (
          <span
            onClick={() =>
              isLink ? onSeeDateDetails(rowData.row, date) : null
            }
            className={isLink ? "text-link" : ""}
          >
            {hasInProgressTime ? "In Progress" : hours.toFixed(2)}
          </span>
        );
      },
    };
  });

  const columns = [
    {
      accessor: "employee",
      header: "Employee",
      headerProps: { className: "text-truncate" },
      cellProps: {
        className: "text-truncate",
      },
      Cell: (rowData) => {
        const { employee } = rowData.row;
        return employee;
      },
    },
    ...weekDays,
    {
      accessor: "hours",
      header: "Total Hours",
      headerProps: { className: "text-truncate" },
      cellProps: {
        className: "text-truncate",
      },
      type: "number",
      Cell: (rowData) => {
        const isInProgress =
          startEndTimeSettingEnabled &&
          Object.values(rowData.row)
            .flatMap((entry) => entry.rawTimes)
            .filter(Boolean)
            .find((time) => time.startTime && !time.endTime);

        const { hours = 0 } = rowData.row;

        if (isInProgress) {
          return (
            <span
              onClick={() => (hours > 0 ? onSeeDateDetails(rowData.row) : null)}
              className={hours > 0 ? "text-link" : ""}
            >
              In Progress
            </span>
          );
        }

        if (hours === 0) {
          return <span>{(0).toFixed(2)}</span>;
        }

        if (rowData.row.isExport) {
          return hours.toFixed(2);
        }

        return (
          <span
            onClick={() => (hours > 0 ? onSeeDateDetails(rowData.row) : null)}
            className={hours > 0 ? "text-link" : ""}
          >
            {hours.toFixed(2)}
          </span>
        );
      },
    },
  ];

  return (
    <Container fluid>
      <div className="w-100">
        <Card className="mb-3 w-100 box-shadow-none">
          <CardHeader className="pt-2 pb-3 d-flex mt-n1">
            <div className="text-dark flex-grow-1 d-flex align-items-center">
              <h2 className="mb-0 ">Payroll Report</h2>
              <small className="text-muted ms-2 pt-1">({data.length})</small>
            </div>
            <div className="d-flex align-items-center justify-content-end col-6">
              <WeekSelector
                className="me-3"
                loading={isLoading}
                weekStart={weekStart}
                setWeekStart={setWeekStart}
              />
              <div className="me-3">
                <DebounceInput
                  className="search border-0 form-control"
                  maxLength={50}
                  minLength={1}
                  debounceTimeout={900}
                  placeholder="Search for.."
                  value={search}
                  onChange={(evt) => {
                    setSearch(evt.target.value);
                  }}
                />
              </div>
              <Button
                size="sm"
                className="me-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
                color="white"
                onClick={() => setRefresh((prev) => !prev)}
                data-testid="refresh-button"
              >
                <FontAwesomeIcon icon={faSync} />
              </Button>
              <div className="table-export-container me-3">
                <div id="table-export" />
              </div>
            </div>
          </CardHeader>
          <CardBody className="p-4 border-radius-default overflow-x-auto">
            <AdvanceTable
              key={Date.now()}
              exportable
              exportName="PayrollReport.csv"
              columns={columns}
              data={data}
              pageSize={Math.max(data.length, 1)}
              isLoading={isLoading}
              headerClassName="text-muted small"
              tableProps={{
                striped: true,
                className: "mb-0 overflow-hidden w-100",
              }}
            />
          </CardBody>
        </Card>
      </div>
      {dateDetailsModal ? (
        <PayrollReportDateDetail
          onClose={(touched) => {
            setDateDetailsModal();
            if (touched) {
              setRefresh(!refresh);
            }
          }}
          entry={dateDetailsModal.entry}
          date={dateDetailsModal.date}
        />
      ) : null}
    </Container>
  );
};

export default PayrollReport;
